import React, { useEffect, useMemo, useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useForm } from "../../hooks";
import * as store from "../../framework/customStore";
import { handleApiError } from "../../models/store/storeEffects";
import { Translations } from "../../models/translations";
import { StrMeasureUnit } from "../../models/common/enums";

import { getDayBookingTypes } from "../../services/dayBookingTypeService";

import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { DayBookingTypeItemBeta } from "../../models/dayBookingType/dayBookingTypeItemBeta";

import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import HorizontalRule from "@mui/icons-material/HorizontalRule";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { Base } from "../../framework/base";
import { SubmitButton } from "../framework/form";
import MuiAutocomplete from "../framework/muiAutocomplete2";
import { showErrorMessage } from "../../models/store/storeActions";
import { useTranslation } from "react-i18next";
import { usePrompt } from "../../hooks/usePrompt";
import { MuiTimeField } from "../framework/muiTimeField";
import { getDayBookingDuration, saveDayBooking } from "../../services/dayBookingService";
import { DayBookingSaveData } from "../../models/dayBooking/dayBookingSaveData";
import { SalaryRowTypeKindCode } from "../../models/salary/salaryRowTypeItem";

interface IDayBookingTypeSelectOption extends DayBookingTypeItemBeta {
    label: string;
    value: string;
}

interface IDayBookingFormItem {
  dayBookingType: IDayBookingTypeSelectOption;
  startTime: Date;
  endTime: Date;
  description: string;
}

interface IWorkTimeDetailsDayBookingFormProps {
  afterSave: () => void;
  editDayBookingItem: DayBookingItemBeta;
  setEditDayBookingItem: React.Dispatch<React.SetStateAction<DayBookingItemBeta>>;
}

const initEmptyDayBooking = (date: string): IDayBookingFormItem => {
    return {
        dayBookingType: null,
        startTime: new Date(date),
        endTime: new Date(date),
        description: "",
    };
};

const initDayBooking = (
    item: DayBookingItemBeta,
    dayBookingTypes: DayBookingTypeItemBeta[]
): IDayBookingFormItem => {
    const dayBookingType: DayBookingTypeItemBeta = dayBookingTypes.find(
        (t) => t.id === item.dayBookingTypeId
    );
    return {
        dayBookingType: dayBookingType
            ? { ...dayBookingType, label: dayBookingType.name, value: dayBookingType.id }
            : null,
        startTime: new Date(item.day),
        endTime: new Date(item.getEndDay()),
        description: item.comment || "",
    };
};

const measureUnits = {
    days: [StrMeasureUnit.Day, StrMeasureUnit.DayFiAlt, StrMeasureUnit.DayEn],
    hours: [StrMeasureUnit.Hour, StrMeasureUnit.HourFi],
    amount: [StrMeasureUnit.Piece]
};

const getDurationFieldLabel = ({ measureUnitIsDays, measureUnitIsHours, measureUnitIsAmount }, isPartialDayBooking: boolean) => {
    let prefix = Translations.Duration;
    let suffix = Translations.AbrDays;
    if(isPartialDayBooking) suffix = Translations.AbrHours;
    if(measureUnitIsDays && !isPartialDayBooking) suffix = Translations.AbrDays;
    if(measureUnitIsHours) suffix = Translations.AbrHours;
    if(measureUnitIsAmount) {
        prefix = Translations.Amount;
        suffix = Translations.MeasureUnitPcs;
    }
    return `${prefix} (${suffix})`;
};

export function WorkTimeDetailsDayBookingForm({
    afterSave,
    editDayBookingItem,
    setEditDayBookingItem
}: IWorkTimeDetailsDayBookingFormProps) {
    const { employeeId, date } = useParams<IWorkTimeDetailsPage>();
    const [edited, setEdited] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const theme = useTheme();
    const [dayBooking, setDayBooking] = useState<IDayBookingFormItem>();
    const [dayBookingTypes, setDayBookingTypes] = useState<DayBookingTypeItemBeta[]>();
    const [amount, setAmount] = useState<number>(0);
    const [calculatedDuration, setCalculatedDuration] = useState<number>();

    const { dayBookingType, startTime, endTime, description } = dayBooking || {};
    let allowEntryInDays = dayBookingType?.allowEntryInDays ?? true;
    const allowEntryInHours = dayBookingType?.allowEntryInHours ?? true;

    const measureUnit = dayBookingType?.salaryRowTypeMeasureUnit;
    const measureUnitIsDays = measureUnits.days.some(unit => unit === measureUnit);
    allowEntryInDays = allowEntryInDays || measureUnitIsDays;
    const measureUnitIsHours = measureUnits.hours.some(unit => unit === measureUnit);
    const measureUnitIsAmount = measureUnits.amount.some(unit => unit === measureUnit);

    const isPartialDayBooking = !measureUnitIsDays &&
        !(!allowEntryInHours && allowEntryInDays && !measureUnitIsHours && !measureUnitIsAmount);

    const showTimeInput =
        measureUnitIsHours || (isPartialDayBooking && !measureUnitIsAmount);

    const days: string = startTime && endTime
        ? (Base.dateDiffInDays(startTime, endTime) + 1).toString()
        : "";
    const duration = allowEntryInDays && measureUnitIsDays
        ? (calculatedDuration ?? days)
        : amount;
    const durationFieldLabel = getDurationFieldLabel({ measureUnitIsDays, measureUnitIsHours, measureUnitIsAmount }, isPartialDayBooking);
    const submitDisabled = Base.isNullOrUndefined(dayBookingType)
      || Base.isNullOrUndefined(startTime)
      || Base.isNullOrUndefined(endTime)
      || (startTime && endTime && moment(endTime).isBefore(startTime));

    const clearDisabled = !dayBookingType && !description;
    const { t } = useTranslation();

    const afterSaveForm = () => {
        clearForm();
        afterSave();
    };

    const clearForm = () => {
        setEditDayBookingItem(null);
        setDayBooking(initEmptyDayBooking(date));
        setEdited(false);
    };

    useEffect(() => {
        if (!showTimeInput) {
            setAmount(Math.floor(amount));
        }
    }, [showTimeInput]);

    const fetchDayBookingDuration = async () => {
        const dbDuration = await getDayBookingDuration({
            dayBookingTypeId: dayBookingType?.value ?? "",
            day: startTime ? Base.dayjsToJsonDate(startTime) : "",
            amount: Base.dateDiffInDays(startTime, endTime) + 1,
        });

        setCalculatedDuration(dbDuration);
    };

    useEffect(() => {
        if (
            startTime &&
            endTime &&
            allowEntryInDays &&
            measureUnitIsDays &&
            dayBookingType?.salaryRowTypeKindCode ==
                SalaryRowTypeKindCode.Vacation
        ) {
            fetchDayBookingDuration();
        } else {
            setCalculatedDuration(null);
        }
    }, [
        startTime,
        endTime,
        allowEntryInDays,
        measureUnitIsDays,
        dayBookingType?.salaryRowTypeKindCode,
    ]);

    const [formRef, submitting, submitForm] = useForm(
        saveDayBooking,
        afterSaveForm,
        useMemo(
            () => ({
                id: editDayBookingItem?.id ?? "",
                employeeId: employeeId,
                dayBookingTypeId: dayBookingType?.value ?? "",
                day: startTime ? Base.dayjsToJsonDate(startTime) : "",
                amount: duration,
                dayUsageType: isPartialDayBooking ? 1 : 0,
                comment: description,
                rowId: editDayBookingItem?.rowId ?? "",
            }),
            [
                employeeId,
                editDayBookingItem?.id,
                editDayBookingItem?.rowId,
                dayBookingType?.value,
                startTime,
                duration,
                isPartialDayBooking,
                description,
            ]
        ),
        DayBookingSaveData,
        t("dayBooking.dayBookingSaveSuccessful")
    );

    const fetchDayBookingTypes = useCallback(async() => {
        setIsLoading(true);
        const results = await getDayBookingTypes();
        setDayBookingTypes(results);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchDayBookingTypes()
            .catch((e) => handleApiError(e, store.customStore.dispatch));
    }, [fetchDayBookingTypes]);

    useEffect(() => {
        if (date && employeeId && dayBookingTypes) {
            setIsLoading(false);

            if (editDayBookingItem?.employeeId === employeeId) {
                setEdited(false);
                setDayBooking(
                    initDayBooking(editDayBookingItem, dayBookingTypes)
                );
            } else {
                clearForm();
            }

            if (editDayBookingItem?.amount) {
                setAmount(editDayBookingItem.amount);
            } else {
                setAmount(0);
            }
        }
    }, [dayBookingTypes, date, employeeId, editDayBookingItem]);

    const handleSubmit = async () => {
        if (submitDisabled) {
            store.customStore.dispatch(
                showErrorMessage(t("unableToSaveChanges"))
            );
            return false;
        }

        return await submitForm();
    };

    const updateDayBooking = (field: string, value: any) => {
        setEdited(true);
        setDayBooking({ ...dayBooking, [field]: value });
    }

    usePrompt(edited, handleSubmit);

    return isLoading ? null : (
        <form ref={formRef}>
            <Typography variant="h6">
                {editDayBookingItem ? Translations.Edit : Translations.AddNew}
            </Typography>
            <Grid2 container spacing={2}>
                <Grid2 xs={12} maxWidth={600}>
                    <MuiAutocomplete
                        label={Translations.DayBookingType}
                        options={dayBookingTypes.map((i) => ({
                            ...i,
                            label: i?.salaryRowTypeMeasureUnit
                                ? `${i.name} (${i.salaryRowTypeMeasureUnit})`
                                : i.name,
                            value: i.id,
                        }))}
                        value={dayBookingType}
                        onChange={(val) =>
                            updateDayBooking("dayBookingType", val)
                        }
                        required
                        autocompleteProps={{ sx: { backgroundColor: "white" } }}
                    />
                </Grid2>
                <Grid2 container xs={24} columns={24} maxWidth={600}>
                    <Grid2 xs={23} lg={9}>
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            locale={fi} // TODO: get from appConfig
                            selected={startTime}
                            onChange={(val: Date) =>
                                updateDayBooking("startTime", val)
                            }
                            showWeekNumbers
                            required
                            popperClassName={"z-3"}
                            customInput={
                                <TextField
                                    label={
                                        !isPartialDayBooking
                                            ? Translations.StartTime
                                            : Translations.Date
                                    }
                                    sx={{ backgroundColor: "white" }}
                                />
                            }
                        />
                    </Grid2>
                    <Grid2
                        xs={false}
                        lg={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        hidden={isPartialDayBooking}
                    >
                        <HorizontalRule />
                    </Grid2>

                    <Grid2 xs={23} lg={9} hidden={isPartialDayBooking}>
                        <DatePicker
                            dateFormat="dd.MM.yyyy"
                            locale={fi} // TODO: get from appConfig
                            selected={endTime}
                            onChange={(val: Date) =>
                                updateDayBooking("endTime", val)
                            }
                            showWeekNumbers
                            required
                            popperClassName={"z-3"}
                            customInput={
                                <TextField
                                    label={Translations.EndTime}
                                    error={endTime < startTime}
                                    sx={{ backgroundColor: "white" }}
                                />
                            }
                        />
                    </Grid2>
                    <Grid2 xs={12} lg={4} maxWidth={104}>
                        {showTimeInput ? (
                            <MuiTimeField
                                label={durationFieldLabel}
                                value={Base.hoursToTimeStr(amount)}
                                onBlur={(val) =>
                                    setAmount(Base.timeStrToHours(val))
                                }
                                required
                            />
                        ) : (                        
                        <TextField
                            label={durationFieldLabel}
                            value={duration}
                            type={"number"}
                            InputProps={{
                                readOnly: !isPartialDayBooking,
                                inputProps: {
                                    min: 0,
                                    max: measureUnitIsHours ? 23 : null,
                                },
                                required: true,
                            }}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setEdited(true);
                                setAmount(parseInt(e?.target?.value));
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                if (measureUnitIsHours)
                                    setAmount(
                                        Math.min(parseInt(e?.target?.value), 23)
                                    );
                            }}
                            sx={{
                                backgroundColor: "white",
                                ".MuiInputLabel-root": { overflow: "visible" },
                            }}
                        />)}
                    </Grid2>
                </Grid2>
                <Grid2 xs={24} maxWidth={600}>
                    <TextField
                        key={editDayBookingItem?.id}
                        label={Translations.Description}
                        value={description}
                        onChange={(e) =>
                            updateDayBooking("description", e.target.value)
                        }
                        multiline
                        minRows={2}
                        fullWidth
                        sx={{ backgroundColor: "white" }}
                    />
                </Grid2>
                <Grid2 xs={12}>
                    <SubmitButton
                        submitting={submitting}
                        disabled={submitDisabled}
                        color="secondary"
                        sx={{
                            float: "right",
                            color: theme.palette.common.white,
                        }}
                    />
                    <Button
                        variant="outlined"
                        disabled={clearDisabled}
                        color="secondary"
                        sx={{
                            float: "right",
                            marginRight: "1rem",
                        }}
                        onClick={clearForm}
                    >
                        {Translations.Clear}
                    </Button>
                </Grid2>
            </Grid2>
        </form>
    );
}
