export const infiniteListPageSize = 60;
export const defaultWorkOrderCalendarStartOfficeTime = 7 * 60;
export const defaultWorkOrderCalendarEndOfficeTime = 19 * 60;
export const hourBookingTypeCategoryNormal = 0;
export const hourBookingTypeCategoryDailyBreak = 1001;
export const hourBookingTypeCatehoryNormalSecondary = 11;
export const hourBookingTypeCategoryOvertimeBase = 50;
export const hourBookingTypeCategoryDeductionStart = 1000;
export const hourBookingTypeCategoryPaidWaiting = 101;
export const hourBookingTypeCategoryPaidBreak = 10;
export const hourBookingTypeCategoryHalfDayAllowance = 21;
export const hourBookingTypeCategoryFullDayAllowance = 22;

export const cultureCodeFinnish = "fi";
export const cultureCodeEnglish = "en-US";

//Employee ApplicationParameterCodes: KEYS HAVE TO BE IN CAMELCASE!
export const employeeParameterWorkOrderCalendarWorkListExpanded = "workOrderCalendarWorkListExpanded_";
export const employeeParameterWorkOrderCalendarCategoryExpanded = "workOrderCalendarCategoryExpanded_";
export const employeeParameterWorkOrderCalendarWorkDetailsExpanded = "workOrderCalendarWorkDetailsExpanded";
export const employeeParameterWorkOrderCalendarAccuracy = "workOrderCalendarAccuracy";
export const employeeParameterWorkOrderCalendarStartOfficeTime = "workOrderCalendarStartOfficeTime";
export const employeeParameterWorkOrderCalendarEndOfficeTime = "workOrderCalendarEndOfficeTime";
export const employeeParameterWorkOrderCalendarSelectedId = "workOrderCalendarSelectedId";
export const employeeParameterFavoriteWorkOrders = "favoriteWorkOrders";
export const employeeParameterMapFilterMode = "mapFilterMode";
export const employeeParameterShowOnMapVehicles = "showOnMapVehicles";
export const employeeParameterShowOnDesignVehicleGroups = "showOnDesignVehicleGroups";
export const employeeParameterShowOnDesignEmployeeGroups = "showOnDesignEmployeeGroups";

export const ownerParameterRequireWorkOrderHourBookings = "RequireWorkOrderHourBookings";

export const PrivacyPolicyUrl = "PrivacyPolicyUrl";
export const ownerParameterSetWorkOrderDurationRoundingMinutes = "SetWorkOrderDurationRoundingMinutes";
export const optionOwnerHasStorageEnabled = "OptionOwnerHasStorageEnabled";
export const optionOwnerHasTransportEnabled = "OptionOwnerHasTransportEnabled";
export const optionOwnerHasTransportInvoicingEnabled = "OptionOwnerHasTransportInvoicingEnabled";
export const optionOwnerHasWorkOrdersEnabled = "OptionOwnerHasWorkOrdersEnabled";

export const optionIsFunctionsAreInUse = "usefunction";
export const ACTIVE_CLASS = " active ";

export const optionOwnerHasReportingEnabled = "OptionOwnerHasReportingEnabled";
export const ReportPath = "/report";
export const ReportingPath = "/reporting";

//OwnerParameter: Salary
export const ownerParameterWorkTimeAccountInUse = "WorkTimeAccountInUse";
export const ownerParameterWorkTimeAccountTransactionDepositSalaryRowTypeCode = "SalaryRowTypeCategoryPuttingHoursToBalance";
export const ownerParameterWorkTimeAccountTransactionWithdrawSalaryRowTypeCode = "SalaryRowTypeCategoryUsingHourBalance";

export const ownerParameterWorkOrderOpenDialogAfterDragAndDrop = "WorkOrderOpenDialogAfterDragAndDrop";

export const optionOwnerHasWorkShiftRoundingEnabled = "OptionOwnerHasWorkShiftRoundingEnabled";
export const optionWorkShiftSplitDurationMinutes = "WorkShiftSplitDurationMinutes"; // determine workshifttimeslot that start "workshift"
export const optionWorkShiftRoundingMinutes = "WorkShiftRoundingMinutes"; // to where round workshift start and end times
export const defaultOptionWorkShiftSplitDurationMinutes = 15; // determine workshifttimeslot that start "workshift"
export const defaultOptionWorkShiftRoundingMinutes = 15; // to where round workshift start and end times

//OwnerParameter: New Invoice
export const optionTransportationInvoicingFixedPriceId = "OptionTransportationInvoicingFixedPriceId";

export const optionWorkTimeVehiclesEnabled = "OptionWorkTimeVehiclesEnabled";
