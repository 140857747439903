import React, { useEffect, useMemo, useState } from "react";
import { Base } from "../../framework/base";
import { useFetch } from "../../hooks/useFetch";

import { ICostCenterItems } from "../../models/costCenter/costCenterItems";
import { SalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { VehicleListItemDto } from "../../models/transport/vehicle";

import { getCostCenterItems } from "../../services/costCenterService";
import { getSalaryRowTypeItems } from "../../services/salaryRowTypeService";
import { getVehicles } from "../../services/vehicleService";

import { MainLayout } from "../layout/mainLayout";
import { VehiclesWorkTimeGrid } from "./vehiclesWorkTimeGrid";
import { WorkTimeVehicleFilters } from "./workTimeVehicleFilters";
import { useAppDispatch, useAppSelector } from "../../framework/customStore";
import { IWorkHoursItem } from "../../models/workTime/workHoursItem";
import * as workHoursService from "../../services/workHoursService";
import { getAllWorkTimeTypes } from "../../services/workTimeTypeService";
import { WorkTimeTypeListItem } from "../../models/workTimeType/workTimeTypeListItem";
import { getEmployees } from "../../services/employeeServiceNew";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { fetchEnd, fetchStart } from "../../models/store/storeActions";

export const VehiclesMain = () => {
    const [vehicles, setVehicles] = useState<VehicleListItemDto[]>();
    const [employees, setEmployees] = useState<EmployeeListItemDto[]>();
    const [salaryRowTypes, setSalaryRowTypes] = useState<SalaryRowTypeItem[]>();
    const [costCenters, setCostCenters] = useState<ICostCenterItems>();
    const [workTimeTypes, setWorkTimeTypes] = useState<WorkTimeTypeListItem[]>();
    const [workHours, setWorkHours] = useState<IWorkHoursItem[]>([]);
    const dispatch = useAppDispatch();

    const timeRange = useAppSelector(state => state.vehiclesWorkTime.filters.timeRange);

    const employeeIds = useMemo(
        () => employees?.map((e) => e.id) ?? [],
        [employees]
    );

    const vehicleIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workHours?.map((w) => w.vehicleId).filter(Boolean) ?? []
            ),
        [workHours]
    );

    const presentVehicles = useMemo(
        () => vehicles?.filter((v) => vehicleIds?.includes(v.id)) ?? [],
        [vehicleIds, vehicles]
    );

    const costCenterIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workHours?.map((w) => w.costCenterId).filter(Boolean) ?? []
            ),
        [workHours]
    );

    const presentCostCenters = useMemo(
        () =>
            costCenters?.items.filter((v) => costCenterIds?.includes(v.id)) ??
            [],
        [costCenterIds, costCenters]
    );

    const workTimeTypeIds = useMemo(
        () =>
            Base.getUniqueStringItems(
                workHours?.map((w) => w.workTimeTypeId).filter(Boolean) ?? []
            ),
        [workHours]
    );

    const presentWorkTimeTypes = useMemo(
        () =>
            workTimeTypes?.filter((v) =>
                workTimeTypeIds?.includes(v.workTimeTypeId)
            ) ?? [],
        [workTimeTypeIds, workTimeTypes]
    );

    const loadWorkHours = async (
        cb: (result: IWorkHoursItem[]) => void,
        signal?: AbortSignal
    ) => {
        dispatch(fetchStart());

        await workHoursService
            .getVehicleWorkHours(
                employeeIds,
                timeRange[0],
                timeRange[1],
                signal
            )
            .then(cb)
            .finally(() => dispatch(fetchEnd()))
            .catch((err) => {
                console.log("error", err);
            });
    };

    useEffect(() => {
        void loadWorkHours((result) => {
            setWorkHours(result);
        });
    }, [timeRange, employeeIds]);

    useFetch(
        (signal) => getVehicles(true, signal),
        {
            onSuccess: (res) => setVehicles(res.data),
        },
        []
    );

    useFetch(
        (signal) => getEmployees(null, signal),
        {
            onSuccess: (res) => setEmployees(res.data),
        },
        []
    );

    useFetch(
        () => getSalaryRowTypeItems(1000, 1, "", "name", true),
        {
            onSuccess: (res) => {
                res.items.sort((a, b) => Base.stringCompare(a.code, b.code));
                setSalaryRowTypes(res.items);
            },
        },
        []
    );

    useFetch(
        () => getCostCenterItems(1000, 1, "", "name", true, true),
        {
            onSuccess: (res) => setCostCenters(res),
        },
        []
    );

    useFetch(
        (signal) => getAllWorkTimeTypes(signal),
        {
            onSuccess: (res) => setWorkTimeTypes(res),
        },
        []
    );

    const dataInitialized = workHours && salaryRowTypes && workTimeTypes;

    return (
        <MainLayout
            noContainer
            className="fluid"
            topComponent={
                <WorkTimeVehicleFilters
                    vehicles={presentVehicles}
                    costCenters={presentCostCenters}
                />
            }
        >
            <div style={{ padding: "1rem" }}>
                {dataInitialized && (
                    <VehiclesWorkTimeGrid
                        employees={employees}
                        salaryRowTypes={salaryRowTypes}
                        vehicles={vehicles}
                        costCenters={costCenters}
                        workTimeTypes={presentWorkTimeTypes}
                        workHours={workHours}
                    />
                )}
            </div>
        </MainLayout>
    );
};
