import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useAppDispatch, useAppSelector, AppDispatch } from "../../framework/customStore";
import { Base } from "../../framework/base";
import { MainLayout } from "../layout/mainLayout";
import { DataGridStyle } from "./components/styledDataGrid";
import { AppUtils } from "../../models/common/appUtils";
import { IEmployeeParameters } from "../../models/employee/employeeIParameters";
import { IApplicationState, IOwnerState, IUserState } from "../../models/store/storeTypes";
import * as storeEffects from "../../models/store/storeEffects";

import { IGetReportResponse, ReportingComponentType } from "../../models/reporting/getReportingResponse";
import { ReportingQueryResponse } from "../../models/reporting/ReportingQueryResponse";
import { getReportQuery } from "../../services/reportingService";
import { getReportParameter } from "../../services/reportingService";
import { ReportingFilters } from "./reportingFilters";
import { setFilters, setRows, setColumns } from "../../store/reportingSlice";
import { ReportingItems } from "../../models/reporting/ReportingQueryResponse";
import { Translations } from "../../models/translations";
import { IHourBookingCategoryItem } from "../../models/hourBookingType/hourBookingTypeItem";
import { getHourBookingTypes } from "../../services/hourBookingService";


export interface IReportingMainStateProps {
    owner: IOwnerState;
    user: IUserState;
}

export interface IReportingMainDispatchProps {
    setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => void;
    onLogout: () => void;
}

type ReqParams = { reportId: string };
type ColumnData = { field: string; headerName: string; width: number; editable: boolean };
type GridData = { columns: ColumnData[]; rows: ReportingItems[] }
interface CategoryById {
    [key: string]: IHourBookingCategoryItem;
}

export const ReportingWorkTime = () => {
    const dispatch : AppDispatch = useAppDispatch();
    const [reportId, setReportId] = useState<string>(null);
    const [required, setRequired] = useState<boolean>(false);
    const { reports, filters, selectedFilters, rows, columns } = useAppSelector(state => state.reporting);
    const [gridData, setGridData] = useState<GridData>({ columns: [], rows: [] });
    const [hourBookingCategories, setHourBookingCategories] =
        useState<CategoryById>();
    const {
        Employee: employeeFilter,
        EmployeeGroup: employeeGroupFilter,
        TimePeriod: timePeriodFilter,
        ShowCustomers: showCustomersFilter,
        ShowOrders: showOrdersFilter,
        ShowCostCenters: showCostCentersFilter
    } = selectedFilters;

    useEffect(() => {
        getHourBookingTypes()
            .then((res) =>
                setHourBookingCategories(
                    Object.fromEntries(res.map((c) => [c.id, c]))
                )
            )
            .catch((e) => storeEffects.handleApiError(e, dispatch));
    }, []);

    useEffect(() => {
        if(!reportId) setReportId(reports?.filter(r => r.title === Translations.WorkTimeReport)?.[0]?.id);
        if(reportId) getReportAndFilters(reportId).catch(console.error);
    }, [reportId, reports]);

    useEffect(() => {
        const req = { reportId: reportId };
        if(employeeFilter.length > 0) req[enumIndex("Employee")] = employeeFilter.toString();
        if(timePeriodFilter) req[enumIndex("TimePeriod")] = timePeriodFilter;
        if(employeeGroupFilter.length > 0) req[enumIndex("EmployeeGroup")] = employeeGroupFilter.toString();
        if(showCustomersFilter) req[enumIndex("ShowCustomers")] = showCustomersFilter;
        if(showOrdersFilter) req[enumIndex("ShowOrders")] = showOrdersFilter;
        if(showCostCentersFilter) req[enumIndex("ShowCostCenters")] = showCostCentersFilter;
        req[enumIndex("OwnerId")] = 1;
        isRequiredExist(req, filters)
            ? search(req).catch(console.error)
            : setRequired(false);
    }, [reportId, filters, selectedFilters]);

    const search = async(req : ReqParams) => {
        try {
            const resp : ReportingQueryResponse = await getReportQuery(req);
            dispatch(setColumns(resp.columnDefination));
            dispatch(setRows(resp.items));
            setRequired(true);
        } catch (e) {
            AppUtils.showErrorMessage(e.message);
        }
    };

    const enumIndex = (type: string) => Object.keys(ReportingComponentType).indexOf(type);

    const getReportAndFilters = async(id: string) => {
        await getReportParameter(id)
            .catch(console.error)
            .then((filters : IGetReportResponse) => {
                dispatch(setFilters(filters));
            });
    };

    const getRequiredList = (reportResponse : IGetReportResponse): string[] => {
        const requiredComponents : string[] = [];
        reportResponse?.components.forEach(c => {
            if (c.isRequired) {
                requiredComponents.push(c.componentTypeName);
            }
        });
        return requiredComponents;
    };

    const isRequiredExist = (req : ReqParams, state: IGetReportResponse) => {
        const requiredList = getRequiredList(state);
        return reportId && requiredList.some(r => (r in req));
    };

    useEffect(() => {
        setGridData(
            hourBookingCategories
                ? {
                      columns: columns,
                      rows: rows
                          .map(
                              (r) =>
                                  ({
                                      ...r,
                                      categoryName:
                                          r.categoryName ||
                                          (hourBookingCategories[
                                              r.category.toString()
                                          ]?.name ??
                                              ""),
                                  } as ReportingItems)
                          )
                          // Hide columns which don't have SalaryRowType and are not linked to any SalaryRowType
                          .filter((i) => !!i.categoryName),
                  }
                : { columns: [], rows: [] }
        );
    }, [rows, columns, hourBookingCategories]);

    return (
        <MainLayout
            topComponent={filters !== null &&
            <ReportingFilters
                filterList={filters?.components}
                requiredFilled={required}
                requiredFields={getRequiredList(filters)}
            />}
        >
            <div style={{ minHeight: "80vh", height: "100%" }}>
                <DataGridStyle data={gridData} />
            </div>
        </MainLayout>
    );
};

export function mapStateToProps(applicationState: IApplicationState): IReportingMainStateProps {
    if (Base.isNullOrUndefined(applicationState) || Base.isNullOrUndefined(applicationState.owner)) {
        return {
            owner: null,
            user: null
        };
    }
    return {
        owner: applicationState.owner,
        user: applicationState.user
    };
}

export function mapDispatchToProps(dispatch: storeEffects.ReportingThunkDispatch): IReportingMainDispatchProps {
    return {
        setEmployeeParameters: (parameters: IEmployeeParameters, saveToDb: boolean) => dispatch<AppDispatch>(storeEffects.setEmployeeParameters(parameters, saveToDb)),
        onLogout: () => dispatch<AppDispatch>(storeEffects.logout())
    };
}

export default connect<IReportingMainStateProps, IReportingMainDispatchProps>(mapStateToProps, mapDispatchToProps)(ReportingWorkTime);
