import { useState, useEffect, useRef, MutableRefObject } from "react";
import { getEmployeeUiState, setEmployeeUiState } from "../services/employeeServiceNew";

interface IUseEmployeeUiState<T> {
    // Note: there is no validation for this, don't assume correct format when using this.
    value: MutableRefObject<T | null | undefined>;
    setValue: (newValue: T) => void;
    initialized: boolean;
}

function useEmployeeUiState<T>(key: string, defaultValue: T): IUseEmployeeUiState<T> {
    const value = useRef<T>(defaultValue);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        void getEmployeeUiState(key)
            .then(res => {
                value.current = res.data as T;
            })
            .finally(() => {
                setInitialized(true);
            });
    }, [key]);

    const setValue = (newValue: any) => {
        value.current = newValue;
        void setEmployeeUiState(key, JSON.stringify(newValue))
            .catch(() => value.current = defaultValue);
    };

    return {
        value,
        setValue,
        initialized
    };
};

export default useEmployeeUiState;