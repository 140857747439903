import React, { useState } from "react";
import { Base } from "../../framework/base";
import { useFetch } from "../../hooks/useFetch";
import { SalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem";
import { getSalaryRowTypeItems } from "../../services/salaryRowTypeService";
import { MainLayout } from "../layout/mainLayout";
import { WorkTimeFilters } from "../workTimeBeta/workTimeFilters";
import SalariesEvents from "./salariesEvents";
import { SalariesListGrid } from "./salariesListGrid";
import { Translations } from "../../models/translations";
import Button from "@mui/material/Button";
import { customHistory } from "../../framework/customHistory";
import { EmployeeListItemDto } from "../../models/employee/employee";
import { useAppSelector } from "../../framework/customStore";
import { getEmployees } from "../../services/employeeServiceNew";

export const SalariesMain = () => {
    const [salaryRowTypes, setSalaryRowTypes] = useState<SalaryRowTypeItem[]>();
    const [employees, setEmployees] = useState<EmployeeListItemDto[]>();

    const selectedEmployeeGroupId = useAppSelector(
        (state) => state.workTimeBeta.filters.selectedEmployeeGroup?.id
    );

    useFetch(
        (signal) => {
            if (selectedEmployeeGroupId) {
                return getEmployees(selectedEmployeeGroupId, signal);
            }
        },
        {
            onSuccess: (res) => setEmployees(res.data),
        },
        [selectedEmployeeGroupId]
    );

    useFetch(
        () => getSalaryRowTypeItems(1000, 1, "", "name", true),
        {
            onSuccess: (res) => {
                res.items.sort((a, b) => Base.stringCompare(a.code, b.code));
                setSalaryRowTypes(res.items);
            },
        },
        []
    );

    if (!salaryRowTypes || !employees) {
        return null;
    }

    return (
        <MainLayout
            noContainer
            className="fluid"
            topComponent={<WorkTimeFilters employees={employees} />}
        >
            <div style={{ padding: "1rem" }}>
                {salaryRowTypes && (
                    <SalariesListGrid salaryRowTypes={salaryRowTypes} />
                )}
                <SalariesEvents />
                <Button
                    variant="contained"
                    onClick={() =>
                        customHistory.push("/worktimepage/salaries/archive")
                    }
                >
                    {Translations.Salaries + "-arkisto"}
                </Button>
            </div>
        </MainLayout>
    );
};
