import React, { useCallback, useMemo, useState } from "react";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";
import { t } from "i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Alert, Box, IconButton, Stack, Typography } from "@mui/material";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import {
    gridClasses,
    GridColDef,
    GridEventListener,
    GridInitialState,
    GridRenderCellParams,
    GridRowParams,
    GridToolbarContainer,
    GridToolbarExport,
    GridValueFormatterParams,
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GRID_TREE_DATA_GROUPING_FIELD,
    useGridApiRef,
    useGridApiContext, useGridSelector, gridRowsLookupSelector,
    gridDetailPanelExpandedRowIdsSelector,
    gridDetailPanelExpandedRowsContentCacheSelector,
    GridRowId,
    GridGroupNode,
    GRID_ROOT_GROUP_ID,
} from "@mui/x-data-grid-premium";

import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import useEmployeeUiState from "../../hooks/useEmployeeUiState";
import { fetchCalendarDays } from "../../store/calendarSlice";
import { Base } from "../../framework/base";
import { useAppSelector } from "../../framework/customStore";
import { StripedDataGrid } from "../framework/muiDataGrid";

import { AppUtils } from "../../models/common/appUtils";
import { SalaryRowTypeItem } from "../../models/salary/salaryRowTypeItem"; ;
import { IWorkHoursItem } from "../../models/workTime/workHoursItem";
import { VehicleListItemDto } from "../../models/transport/vehicle";
import { ICostCenterItems } from "../../models/costCenter/costCenterItems";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";

import { VehicleDetailsTable } from "./vehicleDetailsTable";
import { MuiSwitch } from "../framework/muiSwitch";
import { EmployeeListItemDto } from "../../models/employee/employee";

export interface GridRow {
    id: string;
    hierarchy: string[];
    workHourItems: IWorkHoursItem[];
    salaryRowTypes: SalaryRowTypeItem[];
    employees: EmployeeListItemDto[];
    costCenters: ICostCenterItems;
    workTimeTypes: WorkTimeType[];
    date: string;
    totals: Record<string, number>;
}

interface SalaryListGridProps {
    vehicles: VehicleListItemDto[];
    employees: EmployeeListItemDto[];
    salaryRowTypes: SalaryRowTypeItem[];
    costCenters: ICostCenterItems;
    workTimeTypes: WorkTimeType[];
    workHours: IWorkHoursItem[];
}

export const VehiclesWorkTimeGrid = ({ vehicles, employees, salaryRowTypes, costCenters, workTimeTypes, workHours }: SalaryListGridProps) => {
    const [hideEmptyColumns, setHideEmptyColumns] = useState(true);

    const timeRange = useAppSelector(state => state.vehiclesWorkTime.filters.timeRange);
    const selectedVehicles = useAppSelector(state => state.vehiclesWorkTime.filters.selectedVehicles);
    const selectedCostCenters = useAppSelector(
        (state) => state.vehiclesWorkTime.filters.selectedCostCenters
    );

    const filterByVehicles = (vehicleId: string) => {
        if(!selectedVehicles || selectedVehicles.length === 0) return true;
        if(!selectedVehicles.includes(vehicleId)) return false;
        return true;
    };

    const filterByCostCenters = (costCenterId: string) => {
        if (!selectedCostCenters || selectedCostCenters.length === 0)
            return true;
        if (!selectedCostCenters.includes(costCenterId)) return false;
        return true;
    };

    const displayedWorkHours = useMemo(() => {
        return workHours.filter(
            (w) =>
                !!w.vehicleId &&
                filterByVehicles(w?.vehicleId) &&
                filterByCostCenters(w?.costCenterId)
        );
    }, [workHours, selectedVehicles, selectedCostCenters]);

    const {
        value: persistedGridState,
        setValue: setPersistedGridState,
        initialized: gridStateInitialized
    } = useEmployeeUiState<GridInitialState>("VehiclesWorkTimeGridState", {});

    useAsyncThunkAction(
        () => {
            if (!timeRange[0] || !timeRange[1]) {
                return;
            }
            return fetchCalendarDays({
                startDate: timeRange[0],
                endDate: timeRange[1],
            });
        },
        { onError: () => null },
        [timeRange[0], timeRange[1]]
    );

    const apiRef = useGridApiRef();

    const persistGridState = useCallback(() => {
        if (apiRef?.current?.exportState) {
            const currentState = apiRef.current.exportState();
            setPersistedGridState({
                columns: {
                    orderedFields: currentState.columns.orderedFields
                }
            });
        }
    }, [apiRef]);

    const baseData = displayedWorkHours.map((w) => {
        return {
            id: `${w.vehicleId}`,
            workHourItem: w
        };
    });

    // Group workHours by date
    const groupedData = _groupBy(baseData, (w) => `${w.id}_${w.workHourItem.dateStr}`);

    const columnVisibility = useMemo(
        () =>
            Object.fromEntries([
                ...salaryRowTypes.map((srt) => [
                    srt.id,
                    hideEmptyColumns ? false : true,
                ]),
                ...workTimeTypes.map((wtt) => [
                    wtt.workTimeTypeId,
                    hideEmptyColumns ? false : true,
                ]),
            ]),
        [salaryRowTypes, workTimeTypes, hideEmptyColumns]
    );

    // Create grid rows, calculate totals for each group
    const gridRows: GridRow[] = _map(groupedData, (value, key) => {
        const workHour = value[0].workHourItem;
        const date = workHour.dateStr;
        return {
            id: key,
            hierarchy: [workHour.vehicleId, date],
            workHourItems: value.map((v) => v.workHourItem),
            employees: employees,
            salaryRowTypes: salaryRowTypes,
            costCenters: costCenters,
            workTimeTypes: workTimeTypes,
            date,
            totals: value.reduce(
                (acc, curr) => {
                    acc[curr.workHourItem.salaryRowTypeId] =
                        (acc[curr.workHourItem.salaryRowTypeId] ?? 0) +
                        curr.workHourItem.amount;

                    if (acc[curr.workHourItem.salaryRowTypeId] > 0) {
                        columnVisibility[curr.workHourItem.salaryRowTypeId] =
                            true;
                    }

                    return acc;
                },
                value.reduce((acc, curr) => {
                    acc[curr.workHourItem.workTimeTypeId] =
                        (acc[curr.workHourItem.workTimeTypeId] ?? 0) +
                        curr.workHourItem.amount;

                    if (acc[curr.workHourItem.workTimeTypeId] > 0) {
                        columnVisibility[curr.workHourItem.workTimeTypeId] =
                            true;
                    }

                    return acc;
                }, {})
            ),
        };
    }).sort((a, b) => Base.stringCompare(a.date, b.date));

    const gridDef: GridColDef<GridRow>[] = useMemo(
        () => [
            ...salaryRowTypes.map(
                (srt): GridColDef<GridRow> => ({
                    field: srt.id,
                    headerName: `${srt.name} (${srt.measureUnit})`,
                    description: `${srt.name} (${srt.measureUnit})`,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        const divisor = AppUtils.isTimeUnit(srt.measureUnit) ? 60 : 1;
                        return (params.row?.totals?.[srt.id] ?? 0) / divisor;
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(srt.decimals ?? 2, "fi-Fi");
                    },
                })
            ),
            ...workTimeTypes.map(
                (wtt): GridColDef<GridRow> => ({
                    field: wtt.workTimeTypeId,
                    headerName: wtt.name,
                    description: wtt.name,
                    groupable: false,
                    type: "number",
                    minWidth: 100,
                    flex: 1,
                    valueGetter(params) {
                        return parseFloat(
                            (
                                (params.row?.totals?.[wtt.workTimeTypeId] ??
                                    0) / 60
                            ).toFixed(2)
                        );
                    },
                    valueFormatter: (
                        params: GridValueFormatterParams<number>
                    ) => {
                        const value = params.value;
                        if (!value) return "-";
                        return value.toLocaleFixed(2, "fi-Fi");
                    },
                })
            ),
        ],
        [salaryRowTypes, workTimeTypes]
    );

    const columnVisibilityModel = {
        [GRID_DETAIL_PANEL_TOGGLE_FIELD]: false,
        ...columnVisibility,
    };

    const initialState: GridInitialStatePremium = {
        aggregation: {
            model: Object.fromEntries([
                ...salaryRowTypes.map((srt) => [srt.id, "sum"]),
                ...workTimeTypes.map((wtt) => [wtt.workTimeTypeId, "sum"]),
            ]),
        },
        columns: {
            ...persistedGridState?.current?.columns,
            columnVisibilityModel,
        },
    };

    const onRowClick: GridEventListener<"rowClick"> = (params) => {
        const rowNode = apiRef.current.getRowNode(params.id);
        if (!rowNode) return;

        if (rowNode.type === "group") {
            apiRef.current.setRowChildrenExpansion(params.id, !rowNode.childrenExpanded);
        } else if (rowNode.type === "leaf") {
            apiRef.current.toggleDetailPanel(params.id);
        }
    };

    const getDetailPanelContent = useCallback((params: GridRowParams) => {
        return <VehicleDetailsTable row={params.row} />;
    }, []);

    const ExpandOrCollapseAllButton = () => {
        const [isExpanded, setIsExpanded] = useState(false);
        const apiRef = useGridApiContext();
        const expandedRowIds = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowIdsSelector,
        );
        const rowsWithDetailPanels = useGridSelector(
            apiRef,
            gridDetailPanelExpandedRowsContentCacheSelector,
        );
        const noDetailPanelsOpen = expandedRowIds.length === 0;
        const expandOrCollapseAll = () => {
            const dataRowIdToModelLookup = gridRowsLookupSelector(apiRef);
            const groups = apiRef.current.getRowNode<GridGroupNode>(GRID_ROOT_GROUP_ID)!.children;
            if(!isExpanded) {
                if (groups.length > 0) {
                    for(const group of groups) {
                        apiRef.current.setRowChildrenExpansion(
                            group,
                            true,
                        );
                    }
                }

                const allRowIdsWithDetailPanels: GridRowId[] = Object.keys(rowsWithDetailPanels)
                    .map((key) => apiRef.current.getRowId(dataRowIdToModelLookup[key]));

                apiRef.current.setExpandedDetailPanels(allRowIdsWithDetailPanels);
                setIsExpanded(true);
            } else {
                if (groups.length > 0) {
                    for(const group of groups) {
                        apiRef.current.setRowChildrenExpansion(
                            group,
                            false,
                        );
                    }
                }
                apiRef.current.setExpandedDetailPanels([]);
                setIsExpanded(false);
            }
        };
        const Icon = noDetailPanelsOpen ? UnfoldMoreIcon : UnfoldLessIcon;
        return (
            <IconButton
                size="small"
                tabIndex={-1}
                onClick={expandOrCollapseAll}
                aria-label={noDetailPanelsOpen ? "Expand All" : "Collapse All"}
            >
                <Icon fontSize="inherit" />
            </IconButton>
        );
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                    }}
                />
                <ExpandOrCollapseAllButton />
                <Box flexGrow={1} />
                <MuiSwitch
                    checked={hideEmptyColumns}
                    onChange={() => setHideEmptyColumns(!hideEmptyColumns)}
                    label={t("workTime.hideEmptyColumns")}
                    size="small"
                    labelSlotProps={{ typography: { variant: "subtitle2" } }}
                />
            </GridToolbarContainer>
        );
    };

    return (
        <div style={{ padding: "1rem" }} id="vehicles-container">
            <Typography variant="h3">{t("vehicle.vehicles")}</Typography>
            {gridStateInitialized && (
                <StripedDataGrid
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnOrderChange={persistGridState}
                    initialState={initialState}
                    slots={{
                        noRowsOverlay: () => <Alert severity="info" sx={{ p: 2 }}>{t("vehicle.noRows")}</Alert>,
                        toolbar: CustomToolbar,
                    }}
                    apiRef={apiRef}
                    onRowClick={onRowClick}
                    disableColumnMenu
                    rowSelection={false}
                    density="compact"
                    hideFooter
                    columns={gridDef}
                    rows={gridRows}
                    disableColumnResize
                    treeData
                    getAggregationPosition={() => "inline"}
                    getTreeDataPath={(row: GridRow) => row.hierarchy}
                    groupingColDef={{
                        hideDescendantCount: true,
                        headerName: t("vehicle.vehicle"),
                        valueFormatter: (data) => { // for exports
                            const value = `${data?.value}`;
                            if(value.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)) { // val is vehicleId
                                const vehicle = vehicles.find(v => v?.id === value);
                                return `${vehicle?.registerNumber} - ${vehicle?.brand}`;
                            } else { // val is date
                                return value;
                            }
                        },
                        renderCell: (params: GridRenderCellParams<GridRow>) => <GroupingCol params={params} vehicles={vehicles} />,
                    }}
                    pinnedColumns={{
                        left: [GRID_TREE_DATA_GROUPING_FIELD]
                    }}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={() => "auto"}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                    }
                    sx={{
                        width: "100%",
                        // Hide the "sum" label on headers
                        [`& .${gridClasses.aggregationColumnHeaderLabel}`]: {
                            display: "none"
                        },
                        // disable cell selection style
                        ".MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-row:hover": {
                            cursor: "pointer"
                        },
                        // This is needed to make sticky positioning work for the detail panel
                        "& .MuiDataGrid-detailPanel": {
                            overflow: "visible",
                        },
                        // Needed to display "no rows" overlay correctly
                        ".MuiDataGrid-overlayWrapper": {
                            height: "auto !important",
                        },
                        ".MuiDataGrid-overlayWrapperInner": {
                            height: "auto !important",
                        },

                    }}
                />
            )}
        </div>
    );
};

interface GroupingColProps {
    params: GridRenderCellParams<GridRow>;
    vehicles: VehicleListItemDto[];
}

const GroupingCol = (props: GroupingColProps) => {
    const { params, vehicles } = props;
    const { value: vehicleId } = params;
    const vehicle = vehicles.find(v => v?.id === vehicleId);

    if (params?.rowNode?.type === "group") {
        const open = params.rowNode?.childrenExpanded ?? false;
        return (
            <div>
                {open ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>}
                {`${vehicle?.registerNumber} - ${vehicle?.brand}`}
            </div>
        );
    } else if (params?.rowNode?.type === "leaf") {
        return (
            <Box ml={2}>
                {Base.dayjsToDateStrWithWeekday(params?.row?.date)}
            </Box>
        );
    }
};
