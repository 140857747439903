import * as React from "react";
import { Box } from "@mui/material";
import { AppUtils } from "../../models/common/appUtils";

export interface ILoadingIndicatorProp {
    tooltip?: string;
    classes?: string;
}

export const LoadingIndicator = ({
    classes = "",
    tooltip,
}: ILoadingIndicatorProp) => {

    return (
        <img
            className={`loading-indicator ${classes}`}
            alt={AppUtils.translate("common.loading")}
            title={tooltip || AppUtils.translate("common.loading")}
            src={appConfig.ownRoot + "spinner-loader.gif"}
        />
    );
};


export const LoadingIndicatorInline = ({ classes = "", tooltip }: ILoadingIndicatorProp) => {

    return (
        <Box sx={{ textAlign: "center", my: 2 }}>
            <img
                className={`loading-indicator-inline ${classes}`}
                alt={AppUtils.translate("common.loading")}
                title={tooltip || AppUtils.translate("common.loading")}
                src={appConfig.ownRoot + "spinner-loader.gif"}
            />
        </Box>
    );
};
