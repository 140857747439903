import React from "react";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";
import { customHistory } from "../../framework/customHistory";
import { generatePath, useParams } from "react-router-dom";
import { IWorkTimeDetailsPage } from "./workTimeBetaDetailsPageMain";
import { Translations } from "../../models/translations";
import { AppUtils } from "../../models/common/appUtils";
import Tooltip from "@mui/material/Tooltip";
import { Base } from "../../framework/base";
import { useSelectPaidWorkHoursAmountByDate } from "../../hooks/workTime/useSelectWorkHours";
import dayjs from "dayjs";
import { fetchCalendarDays } from "../../store/calendarSlice";
import { useAsyncThunkAction } from "../../hooks/useAsyncThunkAction";
import { useSelectCalendarDay } from "../../hooks/useCalendar";

interface IWorkTimeDetailsCalendarProps {
    path: string;
}

const durationStrLengthThreshold = 5;

export const WorkTimeDetailsCalendar = ({
    path,
}: IWorkTimeDetailsCalendarProps) => {
    const { employeeId, date, tab } = useParams<IWorkTimeDetailsPage>();

    const handleMonthChange = (date: Date) => {
        customHistory.push(
            generatePath(path, {
                employeeId,
                date: Base.dayjsToJsonDate(date),
                tab,
            })
        );
    };

    useAsyncThunkAction(
        () =>
            fetchCalendarDays({
                startDate: dayjs(date).startOf("month").startOf("week").toISOString(),
                endDate: dayjs(date).endOf("month").endOf("week").toISOString()
            }),
        { onError: () => null },
        [date]
    );

    const renderDayContents = (day: number, date: Date) => {
        return <DayContents day={day} date={date} employeeId={employeeId} />;
    };

    return (
        <div className="worktime-details-datepicker-container">
            <DatePicker
                calendarClassName="worktime-details-datepicker"
                dateFormat="dd.MM.yyyy"
                locale={fi} // TODO: get from appConfig
                selected={new Date(date)}
                onChange={(value) => {
                    customHistory.push(
                        generatePath(path, {
                            employeeId,
                            date: Base.dayjsToJsonDate(value),
                            tab,
                        })
                    );
                }}
                weekLabel={Translations.AbrWeek}
                showWeekNumbers
                inline
                renderDayContents={renderDayContents}
                onMonthChange={handleMonthChange}
            />
        </div>
    );
};

interface DayContentsProps {
    day: number;
    date: Date;
    employeeId: string;
}

const DayContents = (props: DayContentsProps) => {
    const { day, date, employeeId } = props;
    const dateString = Base.dayjsToJsonDate(date);
    const minutes = useSelectPaidWorkHoursAmountByDate(
        employeeId,
        dateString,
        dateString
    );

    const calendarDay = useSelectCalendarDay(dateString);

    const dayProps = {
        title: calendarDay?.description,
        style: { color: calendarDay?.isHoliday ? "red" : "inherit" }
    };

    if (minutes === 0) {
        return <span {...dayProps}>{day}</span>;
    }

    const totalStr = AppUtils.getDurationStrByDurationMinShort(minutes);
    const showTooltip = totalStr.length > durationStrLengthThreshold;

    return (
        <>
            <span {...dayProps}>
                {day}
                <Tooltip
                    title={totalStr}
                    open={showTooltip ? undefined : false}
                >
                    <p className="worktime-details-datepicker-hours text-truncate px-1">
                        {totalStr}
                    </p>
                </Tooltip>
            </span>
        </>
    );
};
