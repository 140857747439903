import React, { useMemo } from "react";
import { StripedTableRow } from "../framework/styledTableRows";
import { StickyTableCellLeft } from "../framework/styledTableCells";
import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@mui/icons-material";
import { IconButton, TableCell } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { AppUtils } from "../../models/common/appUtils";
import { IEmployeeDayData, IOpenEmplyeeDateDetailsParameters, IWorkHoursGrouped } from "./workTimeList";
import Tooltip from "@mui/material/Tooltip";
import { Translations } from "../../models/translations";
import { WorkShiftTimeSlotItem } from "../../models/workShitTimeSlot/workShiftTimeSlotItem";
import { CalendarDay } from "../../models/calendarDay/calendarDay";
import { Base } from "../../framework/base";
import { generatePath } from "react-router-dom";
import { WorkTimeType } from "../../models/workShitTimeSlot/workTimeType";
import { WorkTimeListColumn } from "../../models/workTime/workTimeList";
import Box from "@mui/material/Box";
import { DayBookingItemBeta } from "../../models/dayBooking/dayBookingItemBeta";
import { EmployeeListItemDto } from "../../models/employee/employee";

function getCommentsFromEmployeeDayData(employeeDayData: IEmployeeDayData) {
    const dayBookingComments = employeeDayData.dayBooking.filter(i => i.comment).map(i => i.comment).join("\n");
    const workTimeComments = employeeDayData.workTime.sort((a, b) => Base.dayjsCompare(a.startDate, b.startDate)).filter(i => i.comment).map(i => i.comment).join("\n");
    return dayBookingComments && workTimeComments ? dayBookingComments + "\n" + workTimeComments : dayBookingComments + workTimeComments;
}

const formatTime = (dateStr: string, day: Date, timeZone: string) => {
    if (!dateStr) return null;
    const dayStr = !Base.isSameDay(
        Base.dateStrToDayjsIgnoreTimezone(dateStr),
        day
    )
        ? `${Base.dateStrToOriginalTimezoneShortDateStr(dateStr)} `
        : "";
    const tzSTr = Base.dateTzOffsetIfNotLocal(dateStr, timeZone);

    return `${dayStr}${Base.dateStrToOriginalTimezoneTimeStr(
        dateStr
    )} ${tzSTr}`;
};

export function EmployeeWorkTimesTable(props: {
    employee: EmployeeListItemDto;
    columns: WorkTimeListColumn[];
    workTimeTypes: WorkTimeType[];
    openDetails: (params: IOpenEmplyeeDateDetailsParameters) => void;
    shownColumns: string[];
    employeeDayDatas: IEmployeeDayData[];
    workHoursGrouped: IWorkHoursGrouped[];
    lockRows: (workTimeIds: WorkShiftTimeSlotItem[], dayBookingItems: DayBookingItemBeta[]) => void;
    unlockRows: (workTimeIds: WorkShiftTimeSlotItem[], dayBookingItems: DayBookingItemBeta[]) => void;
    calendarDays: CalendarDay[];
    path: string;
}) {
    const dayRows = useMemo(
        () =>
            props.employeeDayDatas.map((dayRow) => {
                const dateString = Base.dayjsToJsonDate(dayRow.day);
                const workTimes = dayRow.workShifts.flatMap(
                    (x) => x.workShiftTimeSlots
                );
                const firstShift = dayRow.workShifts[0];
                const lastShift =
                    dayRow.workShifts[dayRow.workShifts.length - 1];

                const workTimesGrouped = props.workTimeTypes.map((x) => ({
                    workTimeType: x,
                    duration: workTimes
                        .filter(
                            (wsts) =>
                                !wsts.isCustomType &&
                                x.workTimeTypeId ===
                                    (wsts.workTimeTypeId ?? "wtt_null")
                        )
                        .reduce(
                            (acc, cur) => acc + cur.getDurationMinutes(),
                            0
                        ),
                }));

                const dayBookings = dayRow.dayBooking;
                const workTimesLocked = workTimes.filter((worktime) => !worktime.isLocked()).length === 0;
                const dayBookingsLocked = dayBookings.filter((db) => !db.isLocked()).length === 0;

                return {
                    ...dayRow,
                    dayTitle: Base.dayjsToDateStrWithWeekday(dateString),
                    date: dateString,
                    isLocked: workTimesLocked && dayBookingsLocked,
                    workTime: workTimes,
                    workTimesGrouped,
                    startTime: formatTime(
                        firstShift?.startTime,
                        dayRow.day,
                        firstShift?.startTimeZoneName
                    ),
                    endTime: formatTime(
                        lastShift?.endTime,
                        dayRow.day,
                        lastShift?.endTimeZoneName
                    ),
                    link: generatePath(
                        `${props.path}/details/:employeeId/:date`,
                        {
                            employeeId: props.employee.id,
                            date: dateString,
                        }
                    ),
                };
            }),
        [
            props.employeeDayDatas,
            props.employee.id,
        ]
    );

    return (
        <>
            {dayRows?.map((dayRow) => {
                const calendarDay = props.calendarDays?.find(
                    (i) => Base.isSameDay(i.day, dayRow.day)
                );

                const comments = getCommentsFromEmployeeDayData(dayRow);

                return (
                    <React.Fragment key={dayRow.day.getTime()}>
                        <StripedTableRow>
                            <StickyTableCellLeft
                                onClick={(e) =>
                                    props.openDetails({
                                        employeeId: props.employee.id,
                                        date: dayRow.date,
                                        shoudOpenNewTab: e.ctrlKey,
                                    })
                                }
                                className={calendarDay?.isHoliday ? "dt2" : ""}
                                style={{
                                    width: 120,
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <a
                                    href={dayRow.link}
                                    onClick={(e) => e.preventDefault()}
                                    style={{ color: "inherit" }}
                                >
                                    {dayRow.dayTitle}
                                </a>
                            </StickyTableCellLeft>
                            <TableCell>
                                <Tooltip
                                    title={
                                        dayRow.isLocked
                                            ? null
                                            : Translations.DoLockAll
                                    }
                                >
                                    <IconButton
                                        onClick={
                                            dayRow.isLocked
                                                ? () => {
                                                    props.unlockRows(
                                                        dayRow.workTime,
                                                        dayRow.dayBooking
                                                    );
                                                }
                                                : () => {
                                                    props.lockRows(
                                                        dayRow.workTime,
                                                        dayRow.dayBooking
                                                    );
                                                }
                                        }
                                        aria-label="rows checked"
                                        size="small"
                                        color={
                                            dayRow.isLocked
                                                ? "primary"
                                                : "error"
                                        }
                                    >
                                        {/*TODO: check if any unchecked rows*/}
                                        {dayRow.isLocked ? (
                                            <LockIcon />
                                        ) : (
                                            <LockOpenIcon />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                            <TableCell data-comments={comments}>
                                {comments ? (
                                    <Tooltip
                                        title={
                                            <div
                                                style={{
                                                    whiteSpace: "pre-line",
                                                }}
                                            >
                                                {comments}
                                            </div>
                                        }
                                        arrow
                                    >
                                        <MessageIcon />
                                    </Tooltip>
                                ) : (
                                    ""
                                )}
                            </TableCell>
                            <TableCell className="no-wrap">
                                {dayRow.startTime ?? ""}
                            </TableCell>
                            <TableCell className="no-wrap">
                                {dayRow.endTime ?? ""}
                            </TableCell>
                            {dayRow.workHoursGrouped
                                .filter((w) =>
                                    props.shownColumns.includes(w.column.id)
                                )
                                .map((w, i) => (
                                    <TableCell
                                        style={{
                                            borderLeft:
                                                i === 0
                                                    ? "2px solid var(--palette-TableCell-border)"
                                                    : "",
                                        }}
                                        key={w.column.id}
                                    >
                                        {w.workHoursAmountByCategory?.length >
                                        0 ? (
                                            <Tooltip
                                                title={
                                                    <Box
                                                        sx={{
                                                            whiteSpace:
                                                                "pre-line",
                                                        }}
                                                    >
                                                        {w.workHoursAmountByCategory
                                                            .map(
                                                                (a) =>
                                                                    `${
                                                                        a.categoryName
                                                                    }: ${AppUtils.getDisplayedNumberBasedOnUnit(
                                                                        a.amount,
                                                                        w.column
                                                                            .measureUnit,

                                                                        w.column
                                                                            .decimals,
                                                                        true
                                                                    )}`
                                                            )
                                                            .join("\n")}
                                                    </Box>
                                                }
                                            >
                                                <span>
                                                    {AppUtils.getDisplayedNumberBasedOnUnit(
                                                        w.calculated,
                                                        w.column.measureUnit,
                                                        w.column.decimals,
                                                        true
                                                    )}
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            AppUtils.getDisplayedNumberBasedOnUnit(
                                                w.calculated,
                                                w.column.measureUnit,
                                                w.column.decimals,
                                                true
                                            )
                                        )}
                                    </TableCell>
                                ))}
                            {dayRow.workTimesGrouped
                                .filter((group) =>
                                    props.shownColumns.includes(
                                        group.workTimeType.workTimeTypeId
                                    )
                                )
                                .map((wt, i) => {
                                    return (
                                        <TableCell
                                            key={wt.workTimeType.workTimeTypeId}
                                            style={{
                                                borderLeft:
                                                    i === 0
                                                        ? "2px solid var(--palette-TableCell-border)"
                                                        : "",
                                            }}
                                        >
                                            {wt.duration
                                                ? AppUtils.getDurationStrByDurationMinShort(
                                                      wt.duration
                                                  )
                                                : "-"}
                                        </TableCell>
                                    );
                                })}
                        </StripedTableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
}
