import { getApiCall, postApiCall } from "./tokenService";
import { IWorkHoursItem } from "../models/workTime/workHoursItem";
import { WorkHourSaveData } from "../models/workHour/workHourSaveData";
import { apiCall } from "./apiClient";

export const getCalculatedWorkHours = (
    employeeGroupId: string | undefined,
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    validateMonthlySalaryPeriod = false,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${
            employeeGroupId
                ? `&employeeGroupId=${encodeURIComponent(employeeGroupId)}`
                : ""
        }&validateMonthlySalaryPeriod=${validateMonthlySalaryPeriod}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const getUpToDateWorkHours = (
    employeeId: string,
    startDate: string,
    endDate: string,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours/up-to-date?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(
            endDate
        )}&employeeId=${encodeURIComponent(employeeId)}`,
        null,
        signal
    );
};

export const getCalculatedWorkHoursNew = (
    employeeGroupId: string | undefined,
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    validateMonthlySalaryPeriod = false,
    signal?: AbortSignal
) => {
    return apiCall<IWorkHoursItem[]>(
        `workhours?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${
            employeeGroupId
                ? `&employeeGroupId=${encodeURIComponent(employeeGroupId)}`
                : ""
        }&validateMonthlySalaryPeriod=${validateMonthlySalaryPeriod}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        "GET",
        null,
        signal
    );
};

export const getUpToDateNormalWorkHours = (
    employeeId: string,
    startDate: string,
    endDate: string,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours/normal/up-to-date?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(
            endDate
        )}&employeeId=${encodeURIComponent(employeeId)}`,
        null,
        signal
    );
};

export const getVehicleWorkHours = (
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours/vehicles?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${[employeeIds]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const saveWorkHours = (data: WorkHourSaveData): Promise<void> => {
    return postApiCall<void>("api/workhour/save", null, JSON.stringify(data));
};

export const getSavedWorkHours = (
    employeeGroupId: string | undefined,
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    validateMonthlySalaryPeriod = false,
    signal?: AbortSignal
): Promise<IWorkHoursItem[]> => {
    return getApiCall(
        `api/workhours/saved?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${
            employeeGroupId
                ? `&employeeGroupId=${encodeURIComponent(employeeGroupId)}`
                : ""
        }&validateMonthlySalaryPeriod=${validateMonthlySalaryPeriod}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        null,
        signal
    );
};

export const getSavedWorkHoursNew = (
    employeeGroupId: string | undefined,
    employeeIds: string | string[],
    startDate: string,
    endDate: string,
    validateMonthlySalaryPeriod = false,
    signal?: AbortSignal
) => {
    return apiCall<IWorkHoursItem[]>(
        `workhours/saved?startDate=${encodeURIComponent(
            startDate
        )}&endDate=${encodeURIComponent(endDate)}${
            employeeGroupId
                ? `&employeeGroupId=${encodeURIComponent(employeeGroupId)}`
                : ""
        }&validateMonthlySalaryPeriod=${validateMonthlySalaryPeriod}${[
            employeeIds,
        ]
            .flat()
            .map((id) => `&employeeIds=${encodeURIComponent(id)}`)
            .join("")}`,
        "GET",
        null,
        signal
    );
};
